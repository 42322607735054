import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { themeProps } from './theme';

const useStyles = makeStyles((theme: Theme) => ({
  report: {
    background: theme.palette.primary.main,
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  main: {
    width: '80vw',
    margin: '0 auto',
    maxWidth: '800px',
    padding: '70px 0 2rem 0',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      margin: '0',
      padding: '70px 1rem 2rem 1rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
    },
    '& aside': {
      fontSize: themeProps.textSize.h6,
    },
  },
  header: {
    background: theme.palette.primary.dark,
    flex: '0 0 auto',
  },
  headerButton: {
    color: theme.palette.text.primary,
    marginLeft: '0.5rem',
    transition: 'all 0.3s',
    '&:hover': {
      background: theme.palette.text.primary,
      color: theme.palette.primary.main,
    },
    'headerButton > &': {
      borderBottom: '2px solid #fff',
    },
  },
  summaryTable: {
    '& td': {
      padding: '0.25rem 0.5rem',
    },
  },
  summaryTableBandCell: {
    minWidth: '10ch',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
  },
}));

export default useStyles;
