import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import WeekendIcon from '@mui/icons-material/Weekend';
import WeekendOutlinedIcon from '@mui/icons-material/WeekendOutlined';

import { getSelectedHours } from '../state/selectors';
import { setSelectedHours } from '../state/actions';
import { timeLabel } from '../utils/dataDownload';

const TimeRangeSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 3,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
  },
}));

function SelectHoursPicker(): JSX.Element {
  const dispatch = useDispatch();
  const selectedHours = useSelector(getSelectedHours);
  const { selectHours, startHour, endHour, includeWeekends } = selectedHours;

  const toggleHourSelection = () => {
    if (selectedHours.selectHours) {
      dispatch(setSelectedHours({ ...selectedHours, selectHours: false }));
    } else {
      dispatch(setSelectedHours({ ...selectedHours, selectHours: true }));
    }
  };

  const toggleWeekends = () => {
    if (selectedHours.includeWeekends) {
      dispatch(setSelectedHours({ ...selectedHours, includeWeekends: false }));
    } else {
      dispatch(setSelectedHours({ ...selectedHours, includeWeekends: true }));
    }
  };

  const hoursChanged = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    const minDistance = 1;
    if (Array.isArray(newValue)) {
      let startValue = newValue[0];
      let endValue = newValue[1];
      if (endValue - startValue === 0) {
        if (startValue === 0) endValue = startValue + minDistance;
        else startValue -= minDistance;
      }
      dispatch(
        setSelectedHours({
          ...selectedHours,
          startHour: startValue,
          endHour: endValue,
        })
      );
    }
  };

  return (
    <Box component="span" sx={{ display: 'flex', width: '200px', alignItems: 'center' }}>
      <Tooltip title="Select hours of interest">
        <Checkbox
          inputProps={{
            'aria-label': 'SelectHours',
          }}
          icon={<WorkHistoryOutlinedIcon />}
          checkedIcon={<WorkHistoryIcon />}
          checked={selectHours}
          onChange={toggleHourSelection}
          name="SelectHours"
        />
      </Tooltip>
      {selectHours ? (
        <>
          <TimeRangeSlider
            // key seems to be needed for log error about uncontrolled slider being initialized
            key={`slider-${startHour}-${endHour}`}
            getAriaLabel={() => 'SelectedHours'}
            defaultValue={[startHour, endHour]}
            step={1}
            valueLabelDisplay="on"
            valueLabelFormat={timeLabel}
            max={24}
            min={0}
            marks={[{ value: 9 }, { value: 17 }]}
            onChangeCommitted={hoursChanged}
            size="small"
            name="SelectedHours"
          />
          <Tooltip title="Include weekends">
            <Checkbox
              inputProps={{
                'aria-label': 'IncludeWeekends',
              }}
              icon={<WeekendOutlinedIcon />}
              checkedIcon={<WeekendIcon />}
              checked={includeWeekends}
              onChange={toggleWeekends}
              name="IncludeWeekends"
            />
          </Tooltip>
        </>
      ) : (
        <Typography variant="body1">Select work hours</Typography>
      )}
    </Box>
  );
}

export default SelectHoursPicker;
