import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { themeProps } from './theme';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    display: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  menuButton: {
    color: '#fff',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('lg')]: {
      width: '57px',
    },
    border: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  mobileViewNavDrawer: {
    display: 'none',
    width: '100vw',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  mobileViewNavDrawerPaper: {
    width: '100vw',
    background: theme.palette.primary.main,
  },
  mobileViewNavListContainer: {
    width: '200px',
    marginTop: themeProps.spacers.spacer5,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  mobileViewList: {
    fontFamily: themeProps.fontFamily.titles,
    fontWeight: themeProps.fontWeight.bold,
    fontSize: themeProps.textSize.h6,
    margin: `${themeProps.spacers.spacer4} auto`,
    display: 'block',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    textTransform: 'capitalize',
    width: '200px',
    padding: '0',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mobileViewNavIcon: {
    marginRight: '15px',
  },
  noShadow: {
    boxShadow: 'none',
  },
  whiteBg: {
    background: themeProps.colors.white,
  },
  customBadge: {
    color: 'white',
  },
  navLink: {
    color: '#fff',
    textDecoration: 'none',
    background: theme.palette.primary.main,

    '& button': {
      background: theme.palette.primary.main,

      '&:hover': {
        background: theme.palette.success.main,
      },
    },
  },
  navLinkActive: {
    '& button': {
      background: theme.palette.success.main,
    },
  },
}));

export default useStyles;
